<template>
  <div class="userAgreement">
    <div class="parse_content_text w" v-html="content">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      content: ``
    };
  },
  created () {
    this.getDocument();
  },
  methods: {
    getDocument () {
      this.$api.getDocument({ key: 'agreement' }).then(res => {
        this.content = res.data.content;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.userAgreement {
  padding: 15px 0;
}
</style>